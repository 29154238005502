import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";

type PhoneStatus = "pending" | "ringing";

type OVSession = {
  name: string;
};

type OpenviduState = {
  status: PhoneStatus;
  isLoading: boolean;
  sessionIds: OVSession[];
};

let initialState: OpenviduState = {
  status: "pending",
  isLoading: false,
  sessionIds: [],
};

const openviduSlice = createSlice({
  name: "openvidu",
  initialState,
  reducers: {
    openViduLoaded(state) {
      state.isLoading = false;
    },
    openViduLoading(state) {
      state.isLoading = true;
    },
    setPhoneStatus(state, action: PayloadAction<PhoneStatus>) {
      state.status = action.payload;
    },
    setSessionIds(state, action: PayloadAction<OVSession[]>) {
      state.sessionIds = action.payload;
    },
  },
});

export default openviduSlice.reducer;

export const selectAllOpenviduSessions = (state: RootState) =>
  state.openvidu.sessionIds;

export const {
  setPhoneStatus,
  setSessionIds,
  openViduLoaded,
  openViduLoading,
} = openviduSlice.actions;

import { loginFailed } from "../features/auth/authSlice";
import { returnError } from "../features/error/errorSlice";

/**
 *
 * @param err the error object
 * @param dispatch the redux dispatch function
 */
export default function handleAPIError(err: any, dispatch: any) {
  if (err.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    console.log(err.response.data);
    console.log(err.response.status);
    console.log(err.response.headers);
    const { data, status } = err.response;
    // handle token error (401) this redirects the user to login page
    if (err.response.status === 401) {
      dispatch(loginFailed());
    } else {
      dispatch(returnError({ data, status }));
    }
  } else if (err.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    console.log(err.request);
  } else {
    // Something happened in setting up the request and triggered an Error
    console.log("err", err.message);
    dispatch(returnError({ data: err.message }));
  }
  console.error(err);
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

type ErrorState = {
  data: { msg?: string };
  status?: null | number;
};

let initialState: ErrorState = {
  data: {},
  status: null,
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    returnError(state, action: PayloadAction<ErrorState>) {
      const { data, status } = action.payload;
      state.data = data;
      state.status = status;
      console.error("erreur là :", state.data.msg);
      if (status === 502) toast.error("Proxy error", { autoClose: 3000 });
      if (
        !state.data.msg &&
        state.data.msg === "" &&
        (state.data.msg as {}) === {}
      )
        toast.error("Erreur inconnue", { autoClose: 3000 });
      else toast.error(state.data.msg, { autoClose: 3000 });
    },
    clearError(state) {
      state.data = {};
      state.status = null;
    },
  },
});

export const { returnError, clearError } = errorSlice.actions;

export default errorSlice.reducer;

import { Button } from "@mui/material";
import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { useHistory } from "react-router-dom";
import { posIcon } from "../../icons";
import { POS } from "./posSlice";

type POSMarkerProps = {
  pos: POS;
};

const POSMarker = ({ pos }: POSMarkerProps) => {
  const { location, name } = pos;
  const history = useHistory();

  const openPOSPage = () => history.push(`/pos/${pos.cityId}?posId=${pos._id}`);

  return (
    <Marker position={location} icon={posIcon}>
      <Popup>
        <>
          <Button onClick={openPOSPage}>Voir la page</Button>
        </>
      </Popup>
      <Tooltip>{name}</Tooltip>
    </Marker>
  );
};

export default POSMarker;

let isSubscribed = false;
let swRegistration;
const vapidPublicKey =
  "BCc7UliRO5C9ddswlzqjz9dfyPuIGho1zIVTQ7OdgU4t6--18JdHV_1F21_RVd5q3N9R4NLVhPwOMMHVUqF0nUg";

export default function customServiceWorker() {
  function determineAppServerKey() {
    return urlBase64ToUint8Array(vapidPublicKey);
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  let swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  // navigator.serviceWorker.register(swUrl).then((response) => {
  //   console.warn("response", response);

  //   return response.pushManager.getSubscription().then(function (subscription) {
  //     response.pushManager.subscribe({
  //       userVisibleOnly: true,
  //       applicationServerKey: determineAppServerKey(),
  //     });
  //   });
  // });

  // Installing service worker
  // if ("serviceWorker" in navigator && "PushManager" in window) {
  // if ("serviceWorker" in navigator) {
  // console.log("Service Worker and Push is supported");

  navigator.serviceWorker
    .register(swUrl)
    .then(function (swReg) {
      console.log("service worker registered");

      swRegistration = swReg;

      return swRegistration.pushManager
        .getSubscription()
        .then(function (subscription) {
          isSubscribed = !(subscription === null);

          if (isSubscribed) {
            console.log("User is subscribed");
          } else {
            swRegistration.pushManager
              .subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
              })
              .then(function (subscription) {
                console.log(subscription);
                console.log("User is subscribed");

                saveSubscription(subscription);

                isSubscribed = true;
              })
              .catch(function (err) {
                console.log("Failed to subscribe user: ", err);
                // swReg.unregister();
              });
          }
        });
    })
    .catch(function (error) {
      console.error("Service Worker Error", error);
    });
  // } else {
  //   console.warn("Push messaging is not supported");
  // }

  // Send request to database for add new subscriber
  function saveSubscription(subscription) {
    let xmlHttp = new XMLHttpRequest();
    //put here API address
    xmlHttp.open(
      "POST",
      `${process.env.PUBLIC_URL}/api/notification/subscribe`
    );
    xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState != 4) return;
      if (xmlHttp.status != 200 && xmlHttp.status != 304) {
        console.log("HTTP error " + xmlHttp.status, null);
      } else {
        console.log("User subscribed to server");
      }
    };

    xmlHttp.send(JSON.stringify(subscription));
  }
}

import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";

export type OpeningHours = {
  opening: string | null;
  closing: string | null;
  withdrawalDeadline: string | null;
};

export type WeekOpeningHours = {
  monday: OpeningHours;
  tuesday: OpeningHours;
  wednesday: OpeningHours;
  thursday: OpeningHours;
  friday: OpeningHours;
  saturday: OpeningHours;
  sunday: OpeningHours;
};

type Accomodation = {
  entity: string;
  type: string;
  phoneNumber: string;
  email: string;
  creationContract: string;
  begginingContract: string;
  endingContract: string;
};

export type POS = {
  _id: string;
  name: string;
  openingHours: WeekOpeningHours;
  accomodation: Accomodation;
  available: boolean;
  address: string;
  location: [number, number];
  typeOfTrailerHitch: string;
  furtherInformation: string;
  trailers?: string[];
  cityId: string;
};

export type NewPOS = {
  name: string;
  openingHours: WeekOpeningHours;
  accomodation: Accomodation;
  available: boolean;
  address: string;
  location: [number, number];
  typeOfTrailerHitch: string;
  furtherInformation: string;
  trailers?: string[];
  cityId: string;
};

/**
 * Create an adapter of the POS type
 * see <https://redux-toolkit.js.org/api/createEntityAdapter> for more info
 */
export const posAdapter = createEntityAdapter<POS>({
  selectId: (pos) => pos._id,
});

/**
 * Creates a new redux slice to handle the POS data
 * <https://redux-toolkit.js.org/api/createSlice> for more info
 */
const posSlice = createSlice({
  name: "pointOfSales",
  initialState: posAdapter.getInitialState({
    isLoading: false,
  }),
  reducers: {
    posLoading(state) {
      state.isLoading = true;
    },
    posLoaded(state) {
      state.isLoading = false;
    },
    setPOS: posAdapter.setAll,
    upsertManyPOS: posAdapter.upsertMany,
    removePOS: posAdapter.removeOne,
    modifyPOS: posAdapter.updateOne,
    addPOS: posAdapter.addOne,
  },
});

export const {
  posLoading,
  posLoaded,
  setPOS,
  upsertManyPOS,
  removePOS,
  modifyPOS,
  addPOS,
} = posSlice.actions;

export default posSlice.reducer;

export const posSelectors = posAdapter.getSelectors(
  (state: RootState) => state.pointOfSales
);

export const selectPosByCityId = (cityId: string) => {
  return createSelector(posSelectors.selectAll, (items) =>
    items.filter((item) => item.cityId === cityId)
  );
};

/*
TODO: utiliser cette logique là pour les selecteurs si les performances de la page de planning sont trop mauvaises
Le fait d'utiliser des argument (cityId ici) fait que le selecteur n'est pas memoizé
Et donc qu'il recharge tout le temps le state

const getSelectedCity = (state: RootState) => state.planning.selectedCityId;

export const selectPosByCityId = createSelector(
  [posSelectors.selectAll, getSelectedCity],
  (items, cityId) => items.filter((pos) => pos.cityId === cityId)
);

*/

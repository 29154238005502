import {
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";

export type EutecticPlate = NewEutecticPlate & {
  _id: string;
};

export type NewEutecticPlate = {
  eutecticReference: string;
  eutecticMass: number;
  eutecticType: string;
  eutecticTemperature: number;
  comment: string;
};

const eutecticPlatesAdapter = createEntityAdapter<EutecticPlate>({
  selectId: (entry) => entry._id,
});

type Status = "initial" | "pending" | "fetched";
const status: Status = "initial";

const eutecticPlatesSlice = createSlice({
  name: "eutecticPlates",
  initialState: eutecticPlatesAdapter.getInitialState<{
    status: Status;
  }>({
    status,
  }),
  reducers: {
    eutecticPlatesLoading(state) {
      state.status = "pending";
    },
    eutecticPlatesLoaded(state) {
      state.status = "fetched";
    },
    setEutecticPlates: eutecticPlatesAdapter.setAll,
    upsertManyEutecticPlates: eutecticPlatesAdapter.upsertMany,
    removeEutecticPlate: eutecticPlatesAdapter.removeOne,
    modifyEutecticPlate: eutecticPlatesAdapter.updateOne,
    addEutecticPlate: eutecticPlatesAdapter.addOne,
    addEutecticPlates: eutecticPlatesAdapter.addMany,
  },
});

export const {
  eutecticPlatesLoading,
  eutecticPlatesLoaded,
  setEutecticPlates,
  upsertManyEutecticPlates,
  removeEutecticPlate,
  modifyEutecticPlate,
  addEutecticPlate,
  addEutecticPlates,
} = eutecticPlatesSlice.actions;

export default eutecticPlatesSlice.reducer;

export const eutecticPlatesSelectors = eutecticPlatesAdapter.getSelectors(
  (state: RootState) => state.eutecticPlates
);

export const selectEutecticTemperatures = createSelector(
  [eutecticPlatesSelectors.selectAll],
  (items) => {
    let res: { [key: string]: number } = {};
    items.forEach((ep: EutecticPlate) => {
      res[ep._id] = ep.eutecticTemperature;
    });
    return res;
  }
);

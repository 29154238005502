import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  createSelector,
} from "@reduxjs/toolkit";
import { getWeek, getWeekYear } from "date-fns";
import { RootState } from "../../app/rootReducer";
import { POS } from "../pointOfSales/posSlice";

export type TransportType = "outward" | "return";

export type UnavailableTransporterAndTrailer = {
  transporter: string;
  trailer: string;
  date: string;
  transportType: TransportType;
  pointOfSale: {
    _id: string;
    name: string;
    cityId: {
      _id: string;
      name: string;
    };
  };
};

export type PlanningEntry = {
  _id: string;
  date: string;
  disableWithdrawDate: string;
  transportType: TransportType;
  operator: string;
  transporter: string;
  // the cityId
  city: string;
  pointOfSale: string | POS;
  trailer: { _id: string; name: string } | string;
};

const planningAdapter = createEntityAdapter<PlanningEntry>({
  selectId: (entry) => entry._id,
});

type Status = "initial" | "pending" | "fetched";
const status: Status = "initial";

const planningSlice = createSlice({
  name: "planning",
  initialState: planningAdapter.getInitialState<{
    status: Status;
    selectedCityId: string;
    unavailableTransportersAndTrailers: UnavailableTransporterAndTrailer[];
  }>({
    status,
    selectedCityId: "",
    unavailableTransportersAndTrailers: [],
  }),
  reducers: {
    planningLoading(state) {
      state.status = "pending";
    },
    planningLoaded(state) {
      state.status = "fetched";
    },
    setSelectedCityId(state, action: PayloadAction<string>) {
      state.selectedCityId = action.payload;
    },
    setPlanning: planningAdapter.setAll,
    upsertManyPlanning: planningAdapter.upsertMany,
    removePlanningEntry: planningAdapter.removeOne,
    removePlanningEntries: planningAdapter.removeMany,
    modifyPlanningEntry: planningAdapter.updateOne,
    addPlanningEntry: planningAdapter.addOne,
    addPlanningEntries: planningAdapter.addMany,
    setUnavailableTransporters(
      state,
      action: PayloadAction<UnavailableTransporterAndTrailer[]>
    ) {
      state.unavailableTransportersAndTrailers = action.payload;
    },
  },
});

export const {
  planningLoading,
  planningLoaded,
  setPlanning,
  upsertManyPlanning,
  removePlanningEntry,
  removePlanningEntries,
  modifyPlanningEntry,
  addPlanningEntry,
  addPlanningEntries,
  setUnavailableTransporters,
  setSelectedCityId,
} = planningSlice.actions;

export default planningSlice.reducer;

export const planningSelectors = planningAdapter.getSelectors(
  (state: RootState) => state.planning
);

export const selectPlanningByWeekYearAndCity = (
  cityId: string,
  year: number,
  weekNumber: number
) => {
  return createSelector([planningSelectors.selectAll], (items) =>
    items.filter((planning) => {
      const planningDate = new Date(planning.date);
      if (
        planning.city === cityId &&
        getWeekYear(planningDate, { weekStartsOn: 1 }) === year &&
        getWeek(planningDate, { weekStartsOn: 1 }) === weekNumber
      ) {
        return planning;
      }
      return undefined;
    })
  );
};

import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { AppThunk } from "../../app/store";
import { SERVER_URL } from "../../utils/constants";
import handleAPIError from "../../utils/handleAPIError";
import {
  removeSocleoOrder,
  setTrailersId,
  socleoOrdersLoaded,
  socleoOrdersLoading,
  upsertManySocleoOrders,
  upsertOneSocleoOrder,
} from "./socleoOrdersSlice";

export const ApiGetSocleoOrders = (): AppThunk => async (dispatch) => {
  try {
    dispatch(socleoOrdersLoading());
    const { data } = await axios.get(`${SERVER_URL}/orderSocleo/getall`);
    dispatch(upsertManySocleoOrders(data.orders));
    dispatch(socleoOrdersLoaded());
  } catch (err) {
    dispatch(socleoOrdersLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiRemoveSocleoOrder =
  (_id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(socleoOrdersLoading());
      await axios.delete(`${SERVER_URL}/orderSocleo/delete`, {
        data: { _id },
      });
      dispatch(removeSocleoOrder(_id));
      dispatch(socleoOrdersLoaded());
    } catch (err) {
      dispatch(socleoOrdersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiGetSocleoOrdersByPOSAndDeliveryDate =
  (pos: string, date: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(socleoOrdersLoading());
      const { data } = await axios.get(
        `${SERVER_URL}/orderSocleo/getByPointOfSaleAndDeliverydate/${pos}&${date}`
      );
      dispatch(upsertManySocleoOrders(data.orders));
      dispatch(setTrailersId(data.trailersId));
      dispatch(socleoOrdersLoaded());
    } catch (err) {
      dispatch(socleoOrdersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiCreateSocleoOrder =
  (createData: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(socleoOrdersLoading());
      const { data } = await axios.post(
        `${SERVER_URL}/orderSocleo/create`,
        createData
      );
      dispatch(upsertOneSocleoOrder(data.order));
      dispatch(socleoOrdersLoaded());
      toast.success("Création réussie");
    } catch (err) {
      dispatch(socleoOrdersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiUpdateSocleoOrder =
  (updateData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(socleoOrdersLoading());
      const { data } = await axios.post(
        `${SERVER_URL}/orderSocleo/update`,
        updateData
      );
      dispatch(upsertOneSocleoOrder(data.order));
      dispatch(socleoOrdersLoaded());
      toast.success("Modification réussie");
    } catch (err) {
      dispatch(socleoOrdersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiRemoveLockerFromSocleoOrder =
  (deleteData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(socleoOrdersLoading());
      const { data } = await axios.post(
        `${SERVER_URL}/orderSocleo/deleteLocker`,
        deleteData
      );
      dispatch(upsertOneSocleoOrder(data.order));
      dispatch(socleoOrdersLoaded());
      toast.success("Suppression réussie");
    } catch (err) {
      dispatch(socleoOrdersLoaded());
      handleAPIError(err, dispatch);
    }
  };
export const ApiOpenLocker =
  (lockerToOpen): AppThunk =>
  async (dispatch) => {
    try {
      await axios.post(`${SERVER_URL}/orderSocleo/openLocker`, lockerToOpen);
      toast.success("Casier ouvert !");
    } catch (err) {
      handleAPIError(err, dispatch);
    }
  };

export const ApiInvertSocleoOrdersLockers =
  (invertData): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(socleoOrdersLoading());
      const { data } = await axios.post(
        `${SERVER_URL}/orderSocleo/invertLockers`,
        invertData
      );
      dispatch(upsertManySocleoOrders(data.orders));
      dispatch(socleoOrdersLoaded());
    } catch (err) {
      dispatch(socleoOrdersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiSendSocleoOrdersSms =
  (pointOfSaleId: string, deliveryDate: string): AppThunk =>
  async (dispatch) => {
    console.log("oui");
    try {
      dispatch(socleoOrdersLoading());
      // const { data } = await axios.post(`${SERVER_URL}/orderSocleo/sendSms`);
      await axios.post(`${SERVER_URL}/orderSocleo/sendSms`, {
        pointOfSaleId,
        deliveryDate,
      });
      dispatch(socleoOrdersLoaded());
    } catch (err) {
      dispatch(socleoOrdersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiDownloadDailyPosSummary = async (
  posId: string,
  deliveryDate: string
) => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/orderSocleo/downloadOrdersSummary/${posId}&${deliveryDate}`,
      {
        responseType: "blob",
      }
    );

    const filename = response.headers["content-disposition"].split('"')[1];

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    toast.error("Erreur lors du téléchargement");
  }
};

export const ApiDownloadContainerSummary = async (date: string) => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/orderSocleo/downloadContainerSummary/${date}`,
      {
        responseType: "blob",
      }
    );

    const filename = response.headers["content-disposition"].split('"')[1];

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (err: AxiosError | any) {
    console.error(err.response);
    if (err.response) {
      toast.error(err.response.data.msg);
    } else {
      toast.error("Erreur de lors de la requête");
    }
  }
};

export const ApiDownloadTrailerSummary = async (date: string) => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/orderSocleo/downloadTrailerSummary/${date}`,
      {
        responseType: "blob",
      }
    );

    const filename = response.headers["content-disposition"].split('"')[1];

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (err: AxiosError | any) {
    console.error(err.response);
    if (err.response) {
      toast.error(err.response.data.msg);
    } else {
      toast.error("Erreur de lors de la requête");
    }
  }
};

export const ApiDownloadConditioningSummary = async (deliveryDate: string) => {
  try {
    const response = await axios.get(
      `${SERVER_URL}/orderSocleo/downloadContionningToDoSummary/${deliveryDate}`,
      { responseType: "blob" }
    );

    const filename = response.headers["content-disposition"].split('"')[1];
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (err: AxiosError | any) {
    console.error(err.response);
    if (err.response) {
      toast.error(err.response.data.msg);
    } else {
      toast.error("Erreur de lors de la requête");
    }
  }
};

import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import errorReducer from "../features/error/errorSlice";
import trailersReducer from "../features/trailers/trailersSlice";
import citiesReducer from "../features/cities/citiesSlice";
import usersReducer from "../features/users/usersSlice";
import planningReducer from "../features/planning/planningSlice";
import posReducer from "../features/pointOfSales/posSlice";
import openviduReducer from "../features/openvidu/openviduSlice";
import prestashopOrdersReducer from "../features/prestashopOrders/prestashopOrdersSlice";
import socleoOrdersReducer from "../features/socleoOrders/socleoOrdersSlice";
import basicContainersReducer from "../features/basicContainers/basicContainersSlice";
import globalContainersReducer from "../features/globalContainers/globalContainersSlice";
import eutecticPlatesReducer from "../features/eutecticPlates/eutecticPlatesSlice";
import productsReducer from "../features/products/productsSlice";

// Contains all of the app reducers
const rootReducer = combineReducers({
  auth: authReducer,
  error: errorReducer,
  trailers: trailersReducer,
  cities: citiesReducer,
  users: usersReducer,
  planning: planningReducer,
  pointOfSales: posReducer,
  openvidu: openviduReducer,
  prestashopOrders: prestashopOrdersReducer,
  socleoOrders: socleoOrdersReducer,
  basicContainers: basicContainersReducer,
  globalContainers: globalContainersReducer,
  eutecticPlates: eutecticPlatesReducer,
  products: productsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import L from "leaflet";

export const pointerIcon = new L.Icon({
  iconUrl: "../assets/pointerIcon.svg",
  iconRetinaUrl: "../assets/pointerIcon.svg",
  iconAnchor: [5, 55],
  popupAnchor: [10, -44],
  iconSize: [25, 55],
  shadowUrl: "../assets/marker-shadow.png",
  shadowSize: [68, 95],
  shadowAnchor: [20, 92],
});

export const trailerIcon = new L.Icon({
  iconUrl: "../assets/trailer.svg",
  iconRetinaUrl: "../assets/trailer.svg",
  iconAnchor: [27, 55],
  popupAnchor: [15, -44],
  iconSize: [55, 55],
  tooltipAnchor: [5, -30],
});

export const posIcon = new L.Icon({
  iconUrl: "../assets/pos.svg",
  iconRetinaUrl: "../assets/pos.svg",
  iconAnchor: [27, 55],
  popupAnchor: [15, -44],
  iconSize: [55, 55],
  tooltipAnchor: [5, -30],
});

import {
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { groupBy } from "lodash";
import { RootState } from "../../app/rootReducer";
import { CabinetType } from "../../utils/cabinets";

export type SocleoOrder = {
  _id: string;
  trailerId: string;
  clientName: string;
  type: string;
  code: number;
  phoneNumber: string;
  pointOfSale: string;
  pointOfSaleId: string;
  deliveryDate: string;
  lockers: {
    lockerId: string;
    trailerId: string;
    lockerNumber: number;
    lockerFillingNumber: number;
    crate: string;
    box: string;
  }[];
  totalMass: number;
  products: SocleoOrderProduct[];
};

export type SocleoOrderProduct = {
  product: string;
  productNumber: number;
};

const socleoOrdersAdapter = createEntityAdapter<SocleoOrder>({
  selectId: (entry) => entry._id,
  sortComparer: (a, b) => {
    // sort by trailer ID and clientName if trailerId is the same for both orders
    if (a.trailerId === b.trailerId) {
      return a.clientName.localeCompare(b.clientName);
    } else {
      return a.trailerId.localeCompare(b.trailerId);
    }
  },
});

const ordersSlice = createSlice({
  name: "socleoOrders",
  initialState: socleoOrdersAdapter.getInitialState<{
    isLoading: boolean;
    selectedPos: string;
    selectedDate: string;
    trailersId: string[];
    cabinetType: CabinetType;
  }>({
    isLoading: false,
    selectedPos: "",
    selectedDate: "",
    trailersId: [],
    cabinetType: "2c9l2c6l",
  }),
  reducers: {
    socleoOrdersLoading(state) {
      state.isLoading = true;
    },
    socleoOrdersLoaded(state) {
      state.isLoading = false;
    },
    setSelectedDateAndPos(
      state,
      action: PayloadAction<{ date: string; pos: string }>
    ) {
      const { date, pos } = action.payload;

      state.selectedDate = date;
      state.selectedPos = pos;
    },
    setTrailersId(state, action: PayloadAction<string[]>) {
      state.trailersId = action.payload;
    },
    setSocleoOrders: socleoOrdersAdapter.setAll,
    removeSocleoOrder: socleoOrdersAdapter.removeOne,
    upsertManySocleoOrders: socleoOrdersAdapter.upsertMany,
    upsertOneSocleoOrder: socleoOrdersAdapter.upsertOne,
    modifySocleoOrder: socleoOrdersAdapter.updateOne,
    addSocleoOrder: socleoOrdersAdapter.addOne,
  },
});

export const {
  socleoOrdersLoading,
  socleoOrdersLoaded,
  setSocleoOrders,
  setSelectedDateAndPos,
  setTrailersId,
  removeSocleoOrder,
  upsertManySocleoOrders,
  upsertOneSocleoOrder,
  modifySocleoOrder,
  addSocleoOrder,
} = ordersSlice.actions;

export default ordersSlice.reducer;

export const {
  selectById: selectSocleoOrderById,
  selectIds: selectSocleoOrderIds,
  selectEntities: selectSocleoOrderEntities,
  selectAll: selectAllSocleoOrders,
  selectTotal: selectTotalSocleoOrders,
} = socleoOrdersAdapter.getSelectors((state: RootState) => state.socleoOrders);

const selectedDate = (state: RootState) => state.socleoOrders.selectedDate;
const selectedPos = (state: RootState) => state.socleoOrders.selectedPos;

export const selectGroupedSocleoOrdersByPOSAndDeliveryDate = createSelector(
  [selectAllSocleoOrders, selectedDate, selectedPos],
  (items, date, pos) => {
    const filtered = items.filter((socleoOrder) => {
      if (
        socleoOrder.deliveryDate === date &&
        socleoOrder.pointOfSaleId === pos
      ) {
        return socleoOrder;
      }
      return undefined;
    });

    console.log("filtered", filtered);

    const grouped = groupBy(filtered, (value: SocleoOrder) => value.trailerId);
    return Object.keys(grouped).map((el) => grouped[el]);
  }
);

export const selectSocleoOrdersByPosAndDeliveryDate = createSelector(
  [selectAllSocleoOrders, selectedDate, selectedPos],
  (items, date, pos) => {
    const filtered = items.filter((socleoOrder) => {
      if (
        socleoOrder.deliveryDate === date &&
        socleoOrder.pointOfSaleId === pos &&
        !!socleoOrder.code
      ) {
        return socleoOrder;
      }
      return undefined;
    });

    console.log("filtered", filtered);

    return filtered;
  }
);

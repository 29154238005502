import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";
import { BasicContainer } from "../basicContainers/basicContainersSlice";

export type Product = NewProduct & {
  _id: string;
};

export type StorageTemperature = 65 | 12 | 4 | -18;

export type NewProduct = {
  reference: string;
  producerCode: string;
  producerName: string;
  conditioningToDo: boolean;
  name: string;
  family: string;
  sector: string;
  storageTemperature: StorageTemperature;
  mass: number;
  totalMass: number;
  conditioning: string | BasicContainer;
  volumetricUnit: string;
  storageVolumePerCalculationUnitLiter: number;
};

const productsAdapter = createEntityAdapter<Product>({
  selectId: (entry) => entry._id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

type Status = "initial" | "pending" | "fetched";
const status: Status = "initial";

const productsSlice = createSlice({
  name: "products",
  initialState: productsAdapter.getInitialState<{
    status: Status;
  }>({
    status,
  }),
  reducers: {
    productsLoading(state) {
      state.status = "pending";
    },
    productsLoaded(state) {
      state.status = "fetched";
    },
    setProducts: productsAdapter.setAll,
    upsertManyProducts: productsAdapter.upsertMany,
    removeProduct: productsAdapter.removeOne,
    modifyProduct: productsAdapter.updateOne,
    addProduct: productsAdapter.addOne,
    addProducts: productsAdapter.addMany,
  },
});

export const {
  productsLoading,
  productsLoaded,
  setProducts,
  upsertManyProducts,
  removeProduct,
  modifyProduct,
  addProduct,
  addProducts,
} = productsSlice.actions;

export default productsSlice.reducer;

export const productsSelectors = productsAdapter.getSelectors(
  (state: RootState) => state.products
);

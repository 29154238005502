import { AppThunk } from "../../app/store";
import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import {
  City,
  citiesLoading,
  citiesLoaded,
  setCities,
  addCity,
  removeCity,
  modifyCity,
} from "./citiesSlice";
import handleAPIError from "../../utils/handleAPIError";
import { toast } from "react-toastify";

export const ApiGetCities = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(citiesLoading());
    const { data }: any = await axios.get(`${SERVER_URL}/city/getall`);
    dispatch(setCities(data));
    dispatch(citiesLoaded());
  } catch (err) {
    dispatch(citiesLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiGetCity = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(citiesLoading());
    const { data }: any = await axios.get(`${SERVER_URL}/city/getone/${id}`);
    console.log(data);
    dispatch(setCities([data.city]));
    dispatch(citiesLoaded());
  } catch (err) {
    dispatch(citiesLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiGetCityByNameAndPostCode = (
  name: string,
  postCode: string
): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(citiesLoading());
    const { data }: any = await axios.get(
      `${SERVER_URL}/city/getByNamePc/${name}&${postCode}`
    );
    dispatch(addCity(data));
    dispatch(citiesLoaded());
  } catch (err) {
    dispatch(citiesLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiRemoveCity = (_id: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch(citiesLoading());
    await axios.delete(`${SERVER_URL}/city/delete`, {
      data: { _id },
    });
    dispatch(removeCity(_id));
    toast.success("Suppression réussie");
    dispatch(citiesLoaded());
  } catch (err) {
    dispatch(citiesLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiUpdateCity = (city: City): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch(citiesLoading());
    const { data } = await axios.post(`${SERVER_URL}/city/update`, city);
    console.log({ data });
    dispatch(modifyCity(data.city));
    toast.success("Modification réussie");
    dispatch(citiesLoaded());
  } catch (err) {
    dispatch(citiesLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiCreateCity = (city: City): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    dispatch(citiesLoading());
    const { data } = await axios.post(`${SERVER_URL}/city/create`, city);
    console.log({ data });
    dispatch(addCity(data.city));
    toast.success("Ville créée");
    dispatch(citiesLoaded());
  } catch (err) {
    dispatch(citiesLoaded());
    handleAPIError(err, dispatch);
  }
};

import { Dispatch as DispatchReact } from "react";
import { useCallback, useState, useEffect, SetStateAction } from "react";
import type { AppDispatch } from "../app/store";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AppThunk } from "../app/store";
import { APIDisconnectUser } from "../features/auth/authAPI";

/**
 * logs the user out and then change location
 * @param path the path to go to after logout
 */
export const useDisconnect = (path: string) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return useCallback(() => {
    dispatch(APIDisconnectUser());
    history.push(path);
  }, [dispatch, history, path]);
};

/**
 * plays and pauses a specified sound
 * @param url the URL of the sound to play
 * @returns the status of the sound; a function to toggle play/pause
 */
export const useAudio = (url: string): [boolean, () => void, () => void] => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  audio.loop = true;

  const play = useCallback(() => {
    setPlaying(true);
  }, [setPlaying]);

  const pause = useCallback(() => {
    setPlaying(false);
  }, [setPlaying]);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(true));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [audio]);

  return [playing, play, pause];
};

export const useConfirmationModal = (
  action: (_id: string) => AppThunk
): [
  boolean,
  DispatchReact<SetStateAction<boolean>>,
  DispatchReact<SetStateAction<string | null>>,
  () => void
] => {
  const dispatch = useDispatch();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [id, setId] = useState<null | string>(null);

  const confirmationAction = useCallback(() => {
    if (typeof id === "string") {
      dispatch(action(id));
    }
  }, [id, dispatch, action]);

  return [isConfirmationOpen, setIsConfirmationOpen, setId, confirmationAction];
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// Use throughout your app instead of plain `useDispatch` and `useSelector` (to use .then on dispatch)
export const useAppDispatch = () => useDispatch<AppDispatch>();

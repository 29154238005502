import React, { useState, useEffect } from "react";
import { Route } from "../routes";
import { useHistory, useLocation } from "react-router-dom";
import { ListItem, ListItemText, Collapse, List } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { drawerWidth } from "../utils/constants";

type MenuListProps = {
  items: Route[];
  menuTitle: string;
  closeDrawer: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: drawerWidth,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
}));

const DrawerList = ({ items, menuTitle, closeDrawer }: MenuListProps) => {
  const history = useHistory();
  let location = useLocation();
  const [isListOpen, setIsListOpen] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setIsListOpen((val) => !val);
  };

  useEffect(() => {
    const itemsUrls = items.map((el) => el.url);

    if (itemsUrls.includes(location.pathname)) {
      setIsListOpen(true);
    }
  }, [items, setIsListOpen, location.pathname]);

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={menuTitle} />
        {isListOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={isListOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.nested}>
          {items.map(({ name, url }) => (
            <ListItem
              key={url}
              button
              onClick={() => {
                history.push(url);
                closeDrawer();
              }}
              selected={location.pathname === url}
            >
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default DrawerList;

import { AppThunk } from "../../app/store";
import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import {
  usersLoading,
  usersLoaded,
  setUsers,
  User,
  removeUser,
  modifyUser,
  addUser,
  NewUser,
} from "./usersSlice";
import handleAPIError from "../../utils/handleAPIError";
import { toast } from "react-toastify";

export const ApiGetUsers = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(usersLoading());
    const { data } = await axios.get(`${SERVER_URL}/user/getall`);
    dispatch(setUsers(data.users));
    dispatch(usersLoaded());
  } catch (err) {
    dispatch(usersLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiRemoveUser = (_id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(usersLoading());
    await axios.delete(`${SERVER_URL}/user/delete`, {
      data: { _id },
    });
    dispatch(removeUser(_id));
    toast.success("Suppression réussie");
    dispatch(usersLoaded());
  } catch (err) {
    dispatch(usersLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiUpdateUser = (user: User): AppThunk => async (dispatch) => {
  try {
    dispatch(usersLoading());
    let body: any = user;

    // only send password if it changed
    if (user.password === "") {
      body = { ...body, password: undefined };
    }

    const { data } = await axios.post(`${SERVER_URL}/user/update`, body);

    dispatch(modifyUser({ id: data.user._id, changes: data.user }));
    toast.success("Modification réussie");
    dispatch(usersLoaded());
  } catch (err) {
    dispatch(usersLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiCreateUser = (user: NewUser): AppThunk => async (dispatch) => {
  try {
    dispatch(usersLoading());

    const { data } = await axios.post(`${SERVER_URL}/user/create`, user);

    dispatch(addUser(data.user));
    toast.success("Utilisateur créé");
    dispatch(usersLoaded());
  } catch (err) {
    dispatch(usersLoaded());
    handleAPIError(err, dispatch);
  }
};

// TODO: refaire
export const ApiChangeUserPassword = (
  oldPassword: string,
  newPassword: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(usersLoading());

    const { data } = await axios.post(`${SERVER_URL}/user/changepassword`, {
      oldPassword,
      newPassword,
    });
    console.log(data);

    toast.success("Mot de passe modifié avec succès");
    dispatch(usersLoaded());
  } catch (err) {
    dispatch(usersLoaded());
    handleAPIError(err, dispatch);
  }
};

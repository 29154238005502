import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";

export type BasicContainer = NewBasicContainer & {
  _id: string;
};

export type NewBasicContainer = {
  name: string;
  customerReference: string;
  emptyMass: number;
  volume: number;
  capacity: number;
  buyingPrice: number;
  sellingPrice: number;
  depositPrice: number;
  comment: string;
};

const basicContainersAdapter = createEntityAdapter<BasicContainer>({
  selectId: (entry) => entry._id,
});

type Status = "initial" | "pending" | "fetched";
const status: Status = "initial";

const basicContainersSlice = createSlice({
  name: "basicContainers",
  initialState: basicContainersAdapter.getInitialState<{
    status: Status;
  }>({
    status,
  }),
  reducers: {
    basicContainersLoading(state) {
      state.status = "pending";
    },
    basicContainersLoaded(state) {
      state.status = "fetched";
    },
    setBasicContainers: basicContainersAdapter.setAll,
    upsertManyBasicContainers: basicContainersAdapter.upsertMany,
    removeBasicContainer: basicContainersAdapter.removeOne,
    removeBasicContainers: basicContainersAdapter.removeMany,
    modifyBasicContainer: basicContainersAdapter.updateOne,
    addBasicContainer: basicContainersAdapter.addOne,
    addBasicContainers: basicContainersAdapter.addMany,
  },
});

export const {
  basicContainersLoading,
  basicContainersLoaded,
  setBasicContainers,
  upsertManyBasicContainers,
  removeBasicContainer,
  removeBasicContainers,
  modifyBasicContainer,
  addBasicContainer,
  addBasicContainers,
} = basicContainersSlice.actions;

export default basicContainersSlice.reducer;

export const basicContainersSelectors = basicContainersAdapter.getSelectors(
  (state: RootState) => state.basicContainers
);

import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";

export type PrestashopOrder = {
  _id: string;
  lastname: string;
  firstname: string;
  code: number;
  reference: string;
  idPrestashop: number;
  email: string;
  pointOfSaleId: string;
  deliveryDate: string;
  depositDate: string;
  orderState: {
    name: string;
    template: string;
    send_email: string;
    module_name: string;
    invoice: string;
    color: string;
    unremovable: string;
    logable: string;
    delivery: string;
    hidden: string;
    shipped: string;
    paid: string;
    pdf_invoice: string;
    pdf_delivery: string;
    deleted: string;
    id: number;
    id_shop_list: any;
    force_id: boolean;
  };
  sellerId: {
    shop: {
      name: string;
    };
    _id: string;
  };
  products: {
    _id: string;
    product: string;
    productNumber: number;
  }[];
};

const ordersAdapter = createEntityAdapter<PrestashopOrder>({
  selectId: (entry) => entry._id,
});

const ordersSlice = createSlice({
  name: "prestashopOrders",
  initialState: ordersAdapter.getInitialState({ isLoading: false }),
  reducers: {
    prestashopOrdersLoading(state) {
      state.isLoading = true;
    },
    prestashopOrdersLoaded(state) {
      state.isLoading = false;
    },
    setPrestashopOrders: ordersAdapter.setAll,
    removePrestashopOrder: ordersAdapter.removeOne,
    modifyPrestashopOrder: ordersAdapter.updateOne,
    addPrestashopOrder: ordersAdapter.addOne,
  },
});

export const {
  prestashopOrdersLoading,
  prestashopOrdersLoaded,
  setPrestashopOrders,
  removePrestashopOrder,
  modifyPrestashopOrder,
  addPrestashopOrder,
} = ordersSlice.actions;

export default ordersSlice.reducer;

export const {
  selectById: selectOrderById,
  selectIds: selectOrderIds,
  selectEntities: selectOrderEntities,
  selectAll: selectAllOrders,
  selectTotal: selectTotalOrders,
} = ordersAdapter.getSelectors((state: RootState) => state.prestashopOrders);

import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { RootState } from "../../app/rootReducer";

export type GlobalContainer = NewGlobalContainer & {
  _id: string;
};

export type NewGlobalContainer = {
  name: string;
  customerReference: string;
  emptyMass: number;
  volume: number;
  capacity: number;
  // TODO: ajouter le type eutectic
  eutectic?: string;
  eutecticQuantity: number;
  depositPrice: number;
  comment: string;
};

const globalContainersAdapter = createEntityAdapter<GlobalContainer>({
  selectId: (entry) => entry._id,
});

type Status = "initial" | "pending" | "fetched";
const status: Status = "initial";

const globalContainersSlice = createSlice({
  name: "globalContainers",
  initialState: globalContainersAdapter.getInitialState<{
    status: Status;
  }>({
    status,
  }),
  reducers: {
    globalContainersLoading(state) {
      state.status = "pending";
    },
    globalContainersLoaded(state) {
      state.status = "fetched";
    },
    setGlobalContainers: globalContainersAdapter.setAll,
    upsertManyGlobalContainers: globalContainersAdapter.upsertMany,
    removeGlobalContainer: globalContainersAdapter.removeOne,
    removeGlobalContainers: globalContainersAdapter.removeMany,
    modifyGlobalContainer: globalContainersAdapter.updateOne,
    addGlobalContainer: globalContainersAdapter.addOne,
    addGlobalContainers: globalContainersAdapter.addMany,
  },
});

export const {
  globalContainersLoading,
  globalContainersLoaded,
  setGlobalContainers,
  upsertManyGlobalContainers,
  removeGlobalContainer,
  removeGlobalContainers,
  modifyGlobalContainer,
  addGlobalContainer,
  addGlobalContainers,
} = globalContainersSlice.actions;

export default globalContainersSlice.reducer;

export const globalContainersSelectors = globalContainersAdapter.getSelectors(
  (state: RootState) => state.globalContainers
);

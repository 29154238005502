import React, { useState, useCallback } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { RootState } from "../app/rootReducer";
import { useDisconnect } from "../utils/hooks";
import { translate } from "../utils/translator";
import { drawerWidth } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    // [theme.breakpoints.up("md")]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   marginLeft: drawerWidth,
    //   // background: "#6b8faa",
    // },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  loginButton: {
    color: "white",
  },
}));

type AppBarProps = {
  toggleDrawer: () => void;
};

/**
 * contains menus to navigate between part of the app
 * can toggle the app drawer
 * @param toggleDrawer toggles the App drawer
 */
const MaterialAppBar = ({ toggleDrawer }: AppBarProps) => {
  const classes = useStyles();
  const history = useHistory();
  const disconnect = useDisconnect("/");

  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const firstname = useSelector((state: RootState) => state.auth.firstname);
  const role = useSelector((state: RootState) => state.auth.role);

  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const closeAndDisconnect = useCallback(() => {
    disconnect();
    handleClose();
  }, [disconnect, handleClose]);

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title} noWrap>
          {process.env.REACT_APP_NAME}
        </Typography>
        {
          isAuthenticated ? (
            <>
              <Typography variant="button">
                {firstname} : {role ? translate(role) : null}
              </Typography>
              <IconButton color="inherit" onClick={handleClick} size="large">
                <PersonIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={closeAndDisconnect}>Déconnexion</MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push("/userdashboard");
                    handleClose();
                  }}
                >
                  Profil
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    history.push("/usersadmin");
                    handleClose();
                  }}
                >
                  Administration utilisateurs
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              color="primary"
              className={classes.loginButton}
              disableElevation
              onClick={() => history.push("/login")}
            >
              <Typography variant="button">Se connecter</Typography>
            </Button>
          )
          // <Typography variant="button" onClick={() => history.push("/login")>
          //     Se connecter
          //   </Typography>
        }
      </Toolbar>
    </AppBar>
  );
};

export default MaterialAppBar;

import { AppThunk } from "../../app/store";
import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import {
  NewPOS,
  POS,
  addPOS,
  modifyPOS,
  posLoaded,
  posLoading,
  removePOS,
  upsertManyPOS,
} from "./posSlice";
import handleAPIError from "../../utils/handleAPIError";
import { toast } from "react-toastify";

export const ApiGetPOS = (): AppThunk => async (dispatch) => {
  try {
    dispatch(posLoading());
    const { data } = await axios.get(`${SERVER_URL}/pointOfSale/getall`);

    // dispatch(setPOS(data));
    dispatch(upsertManyPOS(data));
    dispatch(posLoaded());
  } catch (err) {
    handleAPIError(err, dispatch);
  }
};

export const ApiGetPOSByCityId = (cityId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(posLoading());
    const { data } = await axios.get(
      `${SERVER_URL}/pointOfSale/getByCityId/${cityId}`
    );
    dispatch(upsertManyPOS(data));
    dispatch(posLoaded());
  } catch (err) {
    handleAPIError(err, dispatch);
  }
};

export const ApiRemovePOS = (_id: string, cityId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(posLoading());
    await axios.delete(`${SERVER_URL}/pointOfSale/delete`, {
      data: { _id, cityId },
    });
    dispatch(posLoaded());
    toast.success("Suppression réussie");
    dispatch(removePOS(_id));
  } catch (err) {
    handleAPIError(err, dispatch);
  }
};

export const ApiUpdatePOS = (pos: POS): AppThunk => async (dispatch) => {
  try {
    dispatch(posLoading());

    const { data } = await axios.post(`${SERVER_URL}/pointOfSale/update`, pos);
    dispatch(
      modifyPOS({ id: data.pointOfSale._id, changes: data.pointOfSale })
    );
    toast.success("Modification réussie");
    dispatch(posLoaded());
  } catch (err) {
    handleAPIError(err, dispatch);
  }
};

export const ApiCreatePOS = (
  newPos: NewPOS,
  cityId: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(posLoading());

    const body = { ...newPos, cityId };

    const { data } = await axios.post(`${SERVER_URL}/pointOfSale/create`, body);
    console.log({ data });
    dispatch(addPOS(data.pointOfSale));
    toast.success("Création réussie");
    dispatch(posLoaded());
  } catch (err) {
    handleAPIError(err, dispatch);
  }
};

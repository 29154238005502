import React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { trailerIcon } from "../../icons";
import styles from "../../styles/TrailerMarker.module.css";
import { Trailer } from "./trailersSlice";
import { translate } from "../../utils/translator";

type TrailerMarkerProps = {
  trailer: Trailer;
};

const TrailerMarker = ({ trailer }: TrailerMarkerProps) => {
  const {
    location,
    name,
    description,
    state,
    online_cabinets,
    total_cabinets,
    online_tablets,
    total_tablets,
    remaining_orders,
    total_orders,
  } = trailer;

  const badgeCabinet = getBadgeColor(online_cabinets, total_cabinets);
  const badgeTablet = getBadgeColor(online_tablets, total_tablets);
  const badgeMerchandise = getBadgeColor(remaining_orders, total_orders);

  return (
    <Marker position={location} icon={trailerIcon}>
      <Popup>
        <>
          <ul className={styles.container + " list-group list-group-flush"}>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Nom</strong>
              <span className="text-right">{name}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Description</strong>
              <span className="text-right">{description}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>État</strong>
              <span className="text-right">{translate(state)}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Armoires connectées</strong>
              <span className={`text-right badge badge-pill ${badgeCabinet}`}>
                {`${online_cabinets}/${total_cabinets}`}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Tablettes connectées</strong>
              <span className={`text-right badge badge-pill ${badgeTablet}`}>
                {`${online_tablets}/${total_tablets}`}
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              <strong>Commandes en cours</strong>
              <span
                className={`text-right badge badge-pill ${badgeMerchandise}`}
              >
                {`${remaining_orders}/${total_orders}`}
              </span>
            </li>
          </ul>
        </>
      </Popup>
      <Tooltip>{name}</Tooltip>
    </Marker>
  );
};

export default TrailerMarker;

const getBadgeColor = (part: number, total: number) => {
  const val = part / total;
  if (val === 0) {
    return "badge-danger";
  } else if (val === 1) {
    return "badge-success";
  } else {
    return "badge-warning";
  }
};

import { AppThunk } from "../../app/store";
import axios from "axios";
import { SERVER_URL } from "../../utils/constants";

import handleAPIError from "../../utils/handleAPIError";
import { toast } from "react-toastify";
import {
  addGlobalContainer,
  GlobalContainer,
  globalContainersLoaded,
  globalContainersLoading,
  modifyGlobalContainer,
  removeGlobalContainer,
  upsertManyGlobalContainers,
} from "./globalContainersSlice";

export const ApiGetGlobalContainers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(globalContainersLoading());
    const { data }: any = await axios.get(
      `${SERVER_URL}/globalcontainer/getall`
    );
    dispatch(upsertManyGlobalContainers(data));
    dispatch(globalContainersLoaded());
  } catch (err) {
    dispatch(globalContainersLoaded());
    handleAPIError(err, dispatch);
  }
};

export const ApiRemoveGlobalContainer =
  (_id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(globalContainersLoading());
      await axios.delete(`${SERVER_URL}/globalcontainer/delete`, {
        data: { _id },
      });

      dispatch(removeGlobalContainer(_id));
      toast.success("Suppression réussie");
      dispatch(globalContainersLoaded());
    } catch (err) {
      dispatch(globalContainersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiUpdateGlobalContainer =
  (globalContainer: GlobalContainer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(globalContainersLoading());

      const { data } = await axios.post(
        `${SERVER_URL}/globalcontainer/update`,
        globalContainer
      );
      dispatch(
        modifyGlobalContainer({
          id: globalContainer._id,
          changes: data.globalContainer,
        })
      );
      toast.success("Modification réussie");
      dispatch(globalContainersLoaded());
    } catch (err) {
      dispatch(globalContainersLoaded());
      handleAPIError(err, dispatch);
    }
  };

export const ApiCreateGlobalContainer =
  (globalContainer: GlobalContainer): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(globalContainersLoading());

      const { data } = await axios.post(
        `${SERVER_URL}/globalcontainer/create`,
        globalContainer
      );
      dispatch(addGlobalContainer(data.globalContainer));
      toast.success("Le contenant global a bien été créé");
      dispatch(globalContainersLoaded());
    } catch (err) {
      dispatch(globalContainersLoaded());
      handleAPIError(err, dispatch);
    }
  };

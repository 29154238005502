import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type City = {
  _id?: string;
  name: string;
  postCode: number;
  numberOfClients: number;
  img?: Array<{ data: Buffer; contentType: string }>;
  furtherInformation: string;
  pointOfSale?: string[];
  createdAt?: string;
  updatedAt?: string;
  [key: string]: any;
};

type CityState = {
  list: Array<City>;
  isLoading: boolean;
};

let initialState: CityState = {
  list: [],
  isLoading: false,
};

const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    citiesLoading(state) {
      state.isLoading = true;
    },
    citiesLoaded(state) {
      state.isLoading = false;
    },
    setCities(state, action: PayloadAction<[City]>) {
      state.list = action.payload;
    },
    removeCity(state, action: PayloadAction<string>) {
      const _id = action.payload;
      state.list = state.list.filter((el) => el._id !== _id);
    },
    modifyCity(state, action: PayloadAction<City>) {
      const { _id } = action.payload;
      state.list = state.list.map((city) => {
        if (city._id === _id) {
          return action.payload;
        } else {
          return city;
        }
      });
    },
    addCity(state, action: PayloadAction<City>) {
      const newCity = action.payload;
      state.list = [...state.list, newCity];
    },
  },
});

export const {
  citiesLoading,
  citiesLoaded,
  setCities,
  removeCity,
  modifyCity,
  addCity,
} = citiesSlice.actions;

export default citiesSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

type AuthState = {
  isAuthenticated: null | boolean;
  status: "initial" | "pending" | "fetched";
  role: null | string;
  _id: null | string;
  email: null | string;
  firstname: null | string;
  lastname: null | string;
};

let initialState: AuthState = {
  isAuthenticated: false,
  status: "initial",
  role: null,
  _id: null,
  email: null,
  firstname: null,
  lastname: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLoading(state) {
      if (!state.status) {
        state.status = "pending";
      }
    },
    userLoaded(state) {
      if (state.status) {
        state.status = "fetched";
      }
    },
    loginSuccess(state, action: PayloadAction<AuthState>) {
      const { role, _id, email, firstname, lastname } = action.payload;
      state.isAuthenticated = true;
      state.status = "fetched";
      state.role = role;
      state._id = _id;
      state.email = email;
      state.firstname = firstname;
      state.lastname = lastname;
      toast.success(`Bonjour ${state.firstname}`);
    },
    loginFailed(state) {
      state.isAuthenticated = false;
      state.status = "fetched";
      state.role = null;
      state._id = null;
      state.email = null;
      state.firstname = null;
      state.lastname = null;
    },
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.status = "fetched";
      state.role = null;
      state._id = null;
      state.email = null;
      state.firstname = null;
      state.lastname = null;
      toast.info("Déconnexion réussi");
    },
    registerSuccess(state, action: PayloadAction<AuthState>) {},
    registerFailed(state) {
      state.isAuthenticated = false;
      state.status = "fetched";
      state.role = null;
      state._id = null;
      state.email = null;
      state.firstname = null;
      state.lastname = null;
    },
  },
});

export const {
  userLoading,
  userLoaded,
  loginSuccess,
  loginFailed,
  logoutSuccess,
  registerSuccess,
  registerFailed,
} = authSlice.actions;

export default authSlice.reducer;
